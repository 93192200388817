var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "bank-card-icons"
  }, [_c('Visa', {
    staticStyle: {
      "height": "60%"
    }
  }), _c('Mastercard'), _c('Mir', {
    staticStyle: {
      "height": "60%"
    }
  }), _c('Unionpay', {
    staticStyle: {
      "height": "80%"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };